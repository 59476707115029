// extracted by mini-css-extract-plugin
export var box = "_19-0";
export var check = "_19-1";
export var checkboxButton = "_19-6";
export var checkboxGroup = "_19-5";
export var disabled = "_19-7";
export var ghost = "_19-9";
export var input = "_19-2";
export var label = "_19-4";
export var normal = "_19-8";
export var uncheck = "_19-3";