// extracted by mini-css-extract-plugin
export var container = "_o-0";
export var error = "_o-9";
export var label = "_o-5";
export var light = "_o-6";
export var md = "_o-3";
export var sm = "_o-2";
export var thin = "_o-8";
export var thinBold = "_o-7";
export var vertical = "_o-4";
export var xs = "_o-1";