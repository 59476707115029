// extracted by mini-css-extract-plugin
export var active = "_d-3";
export var activeWrapper = "_d-0";
export var button = "_d-1";
export var collapsed = "_d-i";
export var divider = "_d-7";
export var dividerSection = "_d-6";
export var dropdown = "_d-8";
export var dropdownChevron = "_d-a";
export var expand = "_d-9";
export var fullscreen = "_d-r";
export var icon = "_d-n";
export var label = "_d-5 _8-0";
export var labelWithBubble = "_d-p _8-0";
export var logo = "_d-j";
export var logoSection = "_d-m";
export var main = "_d-q";
export var menu = "_d-b";
export var menuHeader = "_d-c _8-0";
export var menuItem = "_d-d _8-0";
export var nav = "_d-e";
export var noBackground = "_d-2";
export var option = "_d-4";
export var options = "_d-g";
export var optionsContainer = "_d-f";
export var page = "_d-h";
export var rotate = "_d-o";
export var section = "_d-k";
export var settings = "_d-t";
export var subitem = "_d-s";
export var subitemActive = "_d-x";
export var subitemLabel = "_d-w";
export var subitemLine = "_d-v";
export var subitemLineContainer = "_d-u";
export var subsection = "_d-l";