// extracted by mini-css-extract-plugin
export var alignBaseline = "_6-8";
export var alignCenter = "_6-4";
export var alignFlexEnd = "_6-6";
export var alignFlexStart = "_6-5";
export var alignSelfBaseline = "_6-d";
export var alignSelfCenter = "_6-9";
export var alignSelfFlexEnd = "_6-b";
export var alignSelfFlexStart = "_6-a";
export var alignSelfStretch = "_6-c";
export var alignStretch = "_6-7";
export var column = "_6-3";
export var flex = "_6-0";
export var justifyCenter = "_6-e";
export var justifyFlexEnd = "_6-g";
export var justifyFlexStart = "_6-f";
export var justifySpaceAround = "_6-i";
export var justifySpaceBetween = "_6-h";
export var justifySpaceEvenly = "_6-j";
export var reverse = "_6-2";
export var row = "_6-1";
export var wrapNowrap = "_6-l";
export var wrapWrap = "_6-k";
export var wrapWrapReverse = "_6-m";