// extracted by mini-css-extract-plugin
export var chip = "_12-9";
export var chipLabel = "_12-a";
export var close = "_12-7";
export var closeIcon = "_12-8";
export var error = "_12-6";
export var infoTooltip = "_12-2";
export var innerContainer = "_12-1";
export var input = "_12-b";
export var medium = "_12-5";
export var outerContainer = "_12-0";
export var small = "_12-4";
export var xSmall = "_12-3";