// extracted by mini-css-extract-plugin
export var accent = "_i-3";
export var add = "_i-h";
export var addIcon = "_i-i";
export var deleteIcon = "_i-g";
export var disabled = "_i-8";
export var ellipsis = "_i-d";
export var error = "_i-2";
export var form = "_i-4";
export var hideFocus = "_i-1";
export var icon = "_i-6";
export var input = "_i-c";
export var listItem = "_i-f";
export var mediumHeight = "_i-a";
export var noBorder = "_i-7";
export var shortHeight = "_i-9";
export var tallHeight = "_i-b";
export var textInput = "_i-0";
export var vertical = "_i-e";
export var widget = "_i-5";