// extracted by mini-css-extract-plugin
export var checkbox = "_z-0";
export var circle = "_z-3";
export var horizontalContainer = "_z-7";
export var sm = "_z-5";
export var switch0 = "_z-2";
export var switchDisabled = "_z-8";
export var switchEnabled = "_z-1";
export var textSwitchContainer = "_z-9";
export var textSwitchOption = "_z-a";
export var textSwitchOptionSelected = "_z-b";
export var verticalContainer = "_z-6";
export var xs = "_z-4";